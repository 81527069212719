import { readCookie, setCookie } from '../../utils/cookie_manager';
import { add_user_info } from './partner_set_cookies';
import { get_utag_search_info } from '../analytics/utag_events';

let get_utag_data = function () {
  let index = window.location.href.indexOf('?');
  let query_string = index > 0 ? window.location.href.substring(index) : '';
  let previous_currency = readCookie('currency') || $("body").data("default-currency");
  let prefix_html = $('html').attr('prefix');
  let url = "/ajax_contents/utag_data/" + query_string
  let url_with_locale = prefix_html === '/' ? url : prefix_html + url;

  if (!IB.cookieManager.groupIsAccepted("analytics")) { return; }

  $.ajax({
    dataType: 'JSON',
    type:     'GET',
    url:      url_with_locale,
    success:  function (response) {
      let data = JSON.stringify(response);
      // Not working in Safari, in Private Browsing Mode
      try {
        sessionStorage.setItem('utag_user', JSON.stringify(response));
        let contact_us_page = document.getElementsByClassName('contact-us');
        if (contact_us_page.length) IB.contactUs.setPrefix();
      } catch (e) {
        console.log('Your web browser does not support storing settings locally. In Safari, the most common cause of this is using Private Browsing Mode. Some settings may not save or some features may not work properly for you.');
      }
      if (data.length > 0) {
        let utagData = JSON.parse(data);
        add_user_info(utagData);
      }

      let currency = readCookie('currency') || $("body").data("default-currency");
      let selector_currency = $(".currency-form select").first().val();
      utag_data = $.extend({},utag_data, {"page_currency": currency});
      if (previous_currency == 'DEF' && currency != 'DEF') {
        IB.currencyForm.updateSelector(currency);
        IB.currencyForm.forceUpdate();
      }
    }
  });
};

function getParameters() {
  let vars = {};
  let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

let get_simulated_market = function () {
  let market = getParameters()['simulate_market'];
  return market;
};

window.ajax_utag_callback = function () {
  let market = get_simulated_market();
  let utagData;
  let fastbooking_data_utag = readCookie('fastbooking_data_utag');
  if (typeof market != 'undefined') {
    get_utag_data();
  } else {
    if ($.cookie('update_utag_data')) {
      get_utag_data();
      IB.cookieManager.deleteCookies(['update_utag_data']);
    }else if (sessionStorage.getItem('utag_user') && sessionStorage.getItem('utag_user') != 'undefined' &&
      sessionStorage.getItem('utag_user') != '' && sessionStorage.getItem('utag_user') != 'null') {
      try {
        utagData = JSON.parse(sessionStorage.getItem('utag_user'));
        add_user_info(utagData);
      } catch (err) {
        ajax_utag_callback();
      }
    } else {
      get_utag_data();
    }
  }
  set_user_id();
  if(fastbooking_data_utag !== null){
    get_utag_search_info();
    //setCookie('fastbooking_data_utag', false, -1)
    IB.cookieManager.deleteCookies(['fastbooking_data_utag'])
  }
};
let check_page_site_origin = function () {
  if (typeof utag_data !== 'undefined' && utag_data !== undefined && $.cookie('page_site_origin') === 'call center') {utag_data['page_site_origin'] = 'call center';}
};

let set_user_id = function () {
  if (typeof utag_data !== 'undefined' && utag_data !== undefined) {utag_data['user_id'] = ($.cookie('user_id') !== undefined && $.cookie('user_id') !== 'uninformed' ? $.cookie('user_id') : '')}
};

//ajax_utag_callback(); This call will be executed in cookie_manager.js instead
check_page_site_origin();
set_user_id();
