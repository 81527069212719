import { sendUtagEvent } from '../../core/analytics/utag_events'
import on from '../../utils/events_delegation'

export function initializeUtagEvents() {
  on({
    eventName: 'click',
    selector: '.last-search-card a.full-link',
    handler(event) {
      var target = event.target

      const event_name = 'last_search'
      const event_cat = 'last search'
      let event_act = ''
      let event_lbl = ''
      let event_purpose = ''
      let event_structure = ''

      /* Evento ver las últimas búsquedas */
      if (target.matches('.last-search-card a.full-link, .last-search-card utag-last-search-view-search')) {
        const event_zone =
          target.closest('.main-nav') !== null ||
          target.closest('.main-header-cnt') !== null ||
          target.closest('.panel-content') !== null ||
          target.closest('.burgermenu-panel') !== null ||
          event.target.closest('.main-nav') ||
          event.target.closest('.mobile-last-searches') ||
          document.body.classList.contains('nhp-home')
            ? 'header'
            : 'body'
        event_act = 'see last search'
        event_purpose = 'booking'
        event_structure = event_zone
        event_lbl = event_zone
      }

      /* Evento compartir últimas búsquedas */
      if (target.matches('.last-search-card a.shared')) {
        event_act = 'share last search'
        event_lbl =
          event.target.closest('.main-nav') !== null ||
          event.target.closest('.main-header-cnt') !== null ||
          event.target.closest('.panel-content') !== null
            ? 'top menu'
            : 'body'

        event_purpose = 'loyalty'
        event_structure = 'body'
      }

      /* Evento enviar últimas búsquedas */
      if (target.matches('#shared-search-form .submit')) {
        event_act = 'send last search'
        event_lbl = 'mail'
        event_purpose = 'booking'
        event_structure = 'popup'
      }

      if (!event_act.length) return
      sendUtagEvent({
        data: {
          event_name,
          event_cat,
          event_act,
          event_lbl,
          event_purpose,
          event_structure,
        },
      })
    },
  })
}
